var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex flex-wrap align-center gap-3"},[_vm._v(" Address Information "),(_vm.addressData.isMonitoring)?_c('MonitoringButton'):_c('gl-icon',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:('This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 1000 incoming transactions'),expression:"'This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 1000 incoming transactions'",arg:"tooltip",modifiers:{"top":true}}],attrs:{"height":24,"name":"def-report","width":24}})],1)]},proxy:true},{key:"body",fn:function(){return [(!_vm.hasTxs && !_vm.addressDataLoading)?_c('div',{staticClass:"wallet-address-wrap pa-2"},[_vm._v(" There are no transactions for this address ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Wallet address","loading":_vm.addressDataLoading,"value":`${_vm.addressData && _vm.addressData.address || _vm.address}`,"word-break-value":""}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Type of the address","loading":_vm.addressDataLoading,"value":"Externally-owned Account (EOA)"}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Token Balance","loading":_vm.addressDataLoading,"rate":_vm.addressData && _vm.addressData.price && _vm.formatByPrice(_vm.addressData.price),"rate-time":"current","rate-timestamp":_vm.addressData && _vm.addressData.priceTimestamp,"value":_vm.addressData
            && _vm.addressData.balance
            && `${_vm.toComaSeparate(String(_vm.restrictNumberAfterComma(_vm.addressData.balance, 8)))} ${_vm.selectedToken.address && _vm.selectedToken.address !== 'all' ? _vm.selectedToken.symbol : _vm.coinData.label}`}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Number of TXS","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.toComaSeparate(String(_vm.txsAddPlus(_vm.addressData.txCount, 2000002))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Total tx sent","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.addressData.txOutputsCount
            && _vm.toComaSeparate(String(_vm.txsAddPlus(_vm.addressData.txOutputsCount))) || '0'}}),_c('InfoBlock',{attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Total tx received","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.addressData.txInputsCount
            && _vm.toComaSeparate(String(_vm.txsAddPlus(_vm.addressData.txInputsCount))) || '0'}})],1),(_vm.featureAccess('ALERTS') && !_vm.selectedToken.address)?_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"key mb-3 fs-14"},[_vm._v(" Profile Flags ")]),_c('GlFlagsWrap',{attrs:{"flags-data":_vm.addressData.flagsData,"hide-score":"","loading":_vm.addressDataLoading,"max-count":2}})],1):_vm._e()]},proxy:true}])}),_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Owner Information ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.isOwnerExist(_vm.addressData))?_c('ReportSectionBody',{attrs:{"data":_vm.addressData,"data-description":_vm.addressData.description,"entity-id":_vm.addressData.entityId,"label-tag":"Extra context tags towards the classification of the requested address","label-top":"Owner / ENS","label-type":"Type of entity that owns requested address","owner-name":`${_vm.addressData && _vm.addressData.owner} / ${_vm.addressData.ens || '--'}`,"tag-meta-data":_vm.addressData.meta,"type-data":_vm.addressData.type}}):_c('div',{staticClass:"pa-2"},[_vm._v(" Not identified ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }