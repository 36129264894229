<template>
  <div>
    <Section class="mb-4">
      <template #title>
        <div class="flex flex-wrap align-center gap-3">
          Address Information
          <MonitoringButton
            v-if="addressData.isMonitoring"
          />
          <gl-icon
            v-else
            v-popover:tooltip.top="'This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 1000 incoming transactions'"
            :height="24"
            name="def-report"
            :width="24"
          />
        </div>
      </template>
      <template #body>
        <div
          v-if="!hasTxs && !addressDataLoading"
          class="wallet-address-wrap pa-2"
        >
          There are no transactions for this address
        </div>
        <div class="pa-2">
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            :inline-view="isMobile ? false : true"
            label="Wallet address"
            :loading="addressDataLoading"
            :value="`${addressData && addressData.address || address}`"
            word-break-value
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            :inline-view="isMobile ? false : true"
            label="Type of the address"
            :loading="addressDataLoading"
            value="Externally-owned Account (EOA)"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            :inline-view="isMobile ? false : true"
            label="Token Balance"
            :loading="addressDataLoading"
            :rate="addressData && addressData.price && formatByPrice(addressData.price)"
            rate-time="current"
            :rate-timestamp="addressData && addressData.priceTimestamp"
            :value="addressData
              && addressData.balance
              && `${toComaSeparate(String(restrictNumberAfterComma(addressData.balance, 8)))} ${selectedToken.address && selectedToken.address !== 'all' ? selectedToken.symbol : coinData.label}`"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            :inline-view="isMobile ? false : true"
            label="Number of TXS"
            :loading="addressDataLoading"
            :value="addressData
              && toComaSeparate(String(txsAddPlus(addressData.txCount, 2000002))) || '0'"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            :inline-view="isMobile ? false : true"
            label="Total tx sent"
            :loading="addressDataLoading"
            :value="addressData
              && addressData.txOutputsCount
              && toComaSeparate(String(txsAddPlus(addressData.txOutputsCount))) || '0'"
          />
          <InfoBlock
            flex-second-column-two
            :inline-view="isMobile ? false : true"
            label="Total tx received"
            :loading="addressDataLoading"
            :value="addressData
              && addressData.txInputsCount
              && toComaSeparate(String(txsAddPlus(addressData.txInputsCount))) || '0'"
          />
        </div>
        <div
          v-if="featureAccess('ALERTS') && !selectedToken.address"
          class="pa-2"
        >
          <div
            class="key mb-3 fs-14"
          >
            Profile Flags
          </div>
          <GlFlagsWrap
            :flags-data="addressData.flagsData"
            hide-score
            :loading="addressDataLoading"
            :max-count="2"
          />
        </div>
      </template>
    </Section>
    <Section class="mb-4">
      <template #title>
        <div> Owner Information </div>
      </template>
      <template #body>
        <ReportSectionBody
          v-if="isOwnerExist(addressData)"
          :data="addressData"
          :data-description="addressData.description"
          :entity-id="addressData.entityId"
          label-tag="Extra context tags towards the classification of the requested address"
          label-top="Owner / ENS"
          label-type="Type of entity that owns requested address"
          :owner-name="`${addressData && addressData.owner} / ${addressData.ens || '--'}`"
          :tag-meta-data="addressData.meta"
          :type-data="addressData.type"
        />
        <div
          v-else
          class="pa-2"
        >
          Not identified
        </div>
      </template>
    </Section>
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon"
import Section from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/ReportSectionBody'
import MonitoringButton from '@/pages/report/components/MonitoringButton'
import InfoBlock from '@/components/gl-info-block';
// utils
import { isOwnerExist} from "@/utils/report-data-formatter";
import { toComaSeparate, restrictNumberAfterComma } from "@/utils/formatNumber";
import { formatByPrice } from "@/utils/format-by-price";
import { txsAddPlus } from '@/utils/txs-add-plus'
// Vuex
import { mapState } from "vuex";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import {featureAccess} from "@/utils/accesses";
import GlFlagsWrap from "@/components/gl-flags-wrap.vue";

export default {
  name: 'EthSectionAddressInfo',
  components: {
    GlFlagsWrap,
    MonitoringButton,
    Section,
    ReportSectionBody,
    InfoBlock,
    GlIcon
  },
  mixins: [deviceWidthMixin],
  props: {
    addressData: {
      type: Object,
      default: () => {},
    },
    hasTxs: {
      type: Boolean,
      default: false,
    },
    addressDataLoading: {
      type: Boolean,
      default: false,
    },
    address: {
      type: [String],
      default: ''
    },
    selectedToken: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapState('analytics', ['coinData'])
  },
  methods: {
    featureAccess,
    isOwnerExist,
    toComaSeparate,
    restrictNumberAfterComma,
    formatByPrice,
    txsAddPlus
  },
}
</script>
